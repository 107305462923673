import React, { useCallback, useRef } from "react";
import logo from "./logo.png";

function App() {
  const input = useRef();
  const [voter, setVoter] = React.useState(null);
  const [error, setError] = React.useState(null);
  const checkVoter = useCallback((e) => {
    e.preventDefault();
    const fetchData = async () => {
      setError(null);

      if (!input.current.value.length > 3) {
        setError("Invalid voter number");
      }
      const res = await fetch(
        `https://ulp-api.mamela.io/voter/${input.current.value}`
      );
      const { message, voter } = await res.json();

      if (message) {
        setVoter(null);
        setError(message);
      } else {
        setError(null);
        setVoter(voter);
      }
    };
    fetchData();
  }, []);

  return (
    <div class="h-screen pb-14 bg-right bg-cover">
      <div class="w-full container mx-auto p-6">
        <div class="w-full flex items-center justify-between">
          <a
            class="flex items-center text-indigo-400 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
            href="#"
          >
            <img class="w-5/6 mx-auto lg:mr-0 slide-in-bottom" src={logo} />
          </a>
        </div>
      </div>
      <div class="container pt-24 md:pt-24 px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="flex flex-col w-full xl:w-2/5 justify-center lg:items-start overflow-y-hidden">
          <h1 class="my-4 text-3xl md:text-5xl text-red-800 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
            Let's Vote
          </h1>
          <p class="leading-normal text-base md:text-2xl mb-8 text-center md:text-left slide-in-bottom-subtitle">
            Enter your voter number to find out where you need to go vote on 5
            November 2020.
          </p>
          {error ? (
            <div
              class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span class="block sm:inline">{error}</span>
            </div>
          ) : null}
          {voter ? (
            <>
              <div class="max-w-sm rounded overflow-hidden shadow-lg mb-4">
                <img
                  class="w-full"
                  src={`http://pollingstation.gov.vc/pollingstation/images/${
                    voter.pollingDivision.pdName
                  }.jpg`}
                  alt=""
                />
                <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2">
                    {voter.stream.location}
                  </div>
                  <div class="font-bold text-xl mb-2">
                    {voter.stream.address}
                  </div>
                  <p class="text-gray-700 text-base">
                    {voter.pollingDivision.constituency.name}
                  </p>
                </div>
              </div>
              <button
                class="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="button"
                onClick={() => setVoter(null)}
              >
                Search Again
              </button>
            </>
          ) : (
            <form class="w-full max-w-sm" onSubmit={checkVoter}>
              <div class="flex items-center border-b border-red-500 py-2">
                <input
                  ref={input}
                  class="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  placeholder="Voter Number"
                  aria-label="Voter number"
                />
                <button
                  class="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
                  type="submit"
                >
                  Check
                </button>
              </div>
            </form>
          )}
        </div>

        <div class="w-full xl:w-3/5 py-6 overflow-y-hidden">
          <img
            class="w-5/6 mx-auto lg:mr-0 slide-in-bottom"
            src={
              "http://voteulp.com/wp-content/uploads/2020/09/ulp-members.jpg"
            }
          />
        </div>

        <div class="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
          <a class="text-gray-500 no-underline hover:no-underline" href="#">
            &copy; 2020, Unity Labour Party. All Rights Reserved.
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
